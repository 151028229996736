<div v-if="variableList" class="row c mt-4">
    <div class="row-col">
        <div class="col">
            <Grid
                v-if="variableList.length > 0"
                :columns="[
                    {
                        slot_name: 'slot_name',
                        label: 'Имя',
                        width: '50%',
                    },
                    {
                        name: 'description',
                        label: 'Описание',
                        // width: 'minmax(10px, 60%)',
                    },
                    {
                        slot_name: 'slot1',
                        label: 'Действия',
                        // width: 'minmax(10px, 10%)',
                    },
                ]"
                :data="variableList"
                :onEdit="undefined"
                :onRemove="undefined"
                :formatters="{
                    area: area => (area) ? area.name : ''
                }"
            >
                <template v-slot:slot_name="row">
                    <div v-if="row.data">
                        <form-button
                            @click="onChange(row.data.id)"
                            :label="`<span class='icon folder'></span> ${row.data.name}`"
                            :attributes="{'class':['btn-link', 'btn-white', 'text-gray']}"
                        />
                    </div>
                </template>
                <template v-slot:slot1="row">
                    <div v-if="row.data">
                        <form-button @click="onEdit(row.data.id)" label="✎" :attributes="{'class':['btn-sm','btn-gray', 'mr-1']}"></form-button>
                        <form-button @click="onDelete(row.data.id)" label="x" :attributes="{'class':['btn-sm','btn-red', 'mr-1']}"></form-button>
                    </div>
                </template>
            </Grid>
            <div v-else class="pl-2">
                Дочерние Свойства и Категории отсутствуют
            </div>
        </div>
    </div>
</div>
