<template src="../templates/variable-grid.html"></template>
<script>

import Grid from '@c/Grid';
import FormButton from '@f/Button';
import FormRadio from '@f/Radio';
import Tabs from '@c/Tabs';
import Loader from '@c/Loader';

export default {
    name: 'VariableGrid',
    components: {
        Grid,
        FormButton,
        FormRadio,
        Tabs,
        Loader,
    },
    props: {
        variables: {
            type: Object,
            default: null
        },
        onEdit: {
            type: Function,
            default() { return () => {}; }
        },
        onDelete: {
            type: Function,
            default() { return () => {}; }
        },
        onChange: {
            type: Function,
            default() { return () => {}; }
        },
    },
    data() {
        return {
        };
    },
    computed: {
        variableList() {
            return Object.values(this.variables);
        }
    },
    methods: {
    }
};
</script>
