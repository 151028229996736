<template src="./templates/variable-editor.html"></template>

<script>
import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import Modal from '@c/Modal';
import Confirm from '@c/Confirm';
import VariableTree from '@c/Scenarios/VariableTree';
import FormButton from '@f/Button';

import {
    getVariables,
    saveVariable,
    removeVariable,
    restoreVariable
} from '@api/scenarios';

import VariableTreeFilter from '@c/Scenarios/VariableTreeFilter';
import VariableGrid from './components/variable-grid';
import EditVariable from './components/edit-variable';
import VariableActions from './components/variable-actions';

export default {
    name: 'VariableEditor',
    components: {
        PageTitle,
        Breadcrumbs,
        PageHeader,
        Modal,
        EditVariable,
        VariableGrid,
        VariableTreeFilter,
        FormButton,
        VariableTree,
        VariableActions,
        Confirm,
    },
    props: {
        size: {
            type: Number,
            required: false,
            default: 15,
        },
    },
    data() {
        return {
            showEditVariableModal: false,
            editVariableData: null,

            showConfirmModal: false,
            confirmBts: {
                title: '',
                buttons: []
            },
            filter: '',
            showRemoved: false,
            removeVariable: null,
        };
    },
    computed: {},
    watch: {},
    methods: {
        actionHandler(args) {
            switch (args.action) {
                case 'add':
                    this.newVariable(args.id);
                    break;
                case 'edit':
                    this.editVariable(args.id);
                    break;
                case 'copy':
                    this.copyVariable(args.id);
                    break;
                case 'delete':
                    this.deleteVariable(args.id);
                    break;
                case 'restore':
                    this.restoreVariable(args.id);
                    break;
                default:
                    console.log('Error 6140: Неизвестное действие');
            }
        },

        closeEditVariableModal(showConfirmDialog = true) {
            this.confirmBts = {
                title: 'Данные были изменены. Сохранить изменения?',
                buttons: [
                    {
                        label: 'Да',
                        class: 'btn-green',
                        action: () => {
                            this.showConfirmModal = false;
                            this.$refs.editVariable.save();
                        },
                    },
                    {
                        label: 'Нет',
                        class: 'btn-red',
                        action: () => {
                            this.showEditVariableModal = false;
                            this.showConfirmModal = false;
                        },
                    },
                    {
                        label: 'Отмена',
                        action: () => {
                            this.showConfirmModal = false;
                        },
                    },
                ]
            };

            if (this.$refs.editVariable.modified && showConfirmDialog) {
                this.showConfirmModal = true;
            } else {
                this.showEditVariableModal = false;
            }
        },
        newVariable(parentId) {
            this.editVariableData = {parent_id: parentId};
            this.showEditVariableModal = true;
        },
        editVariable(variableId) {
            getVariables(
                {
                    id: variableId,
                    limit: 1,
                    withConditions: 1,
                    withSettings: 1,
                },
                r => {
                    this.editVariableData = {...r[variableId]};
                    this.showEditVariableModal = true;
                }
            );
        },
        copyVariable(variableId) {
            getVariables(
                {
                    id: variableId,
                    limit: 1,
                    removed: 0,
                    withConditions: 1,
                },
                r => {
                    const getConditionsCopy = conditions => conditions.map(condition => ({
                        ...condition,
                        id: null,
                        conditions: condition.conditions.map(cond => ({...cond, id: null})),
                        pools: getConditionsCopy(condition.pools),
                    }));

                    const variableData = {
                        ...r[variableId],
                        name: `Копия ${r[variableId].name}`,
                        values: r[variableId].values.map(value => {
                            const valueCopy = {...value};
                            delete valueCopy.id;
                            delete valueCopy.variable_id;
                            delete valueCopy.create_date;
                            delete valueCopy.update_date;
                            return valueCopy;
                        }),
                        pools: r[variableId].pools === null ? null : getConditionsCopy(r[variableId].pools),
                    };
                    delete variableData.id;
                    delete variableData.create_date;
                    delete variableData.update_date;
                    delete variableData.removed;

                    this.editVariableData = variableData;
                    this.showEditVariableModal = true;
                    this.$nextTick(() => {
                        this.$refs.editVariable.modified = true;
                    });
                }
            );
        },
        saveVariable(data) {
            saveVariable(
                {...data},
                () => {
                    const ch = this.$refs.varTree;
                    ch.onUpdateTree();
                    this.closeEditVariableModal(false);
                }
            );
        },
        deleteVariable(variableId) {
            getVariables(
                {
                    id: variableId,
                    limit: 1,
                    removed: 0,
                    withDependents: 1,
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите удалить свойство "${r[variableId].name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    removeVariable(
                                        variableId,
                                        () => {
                                            const ch = this.$refs.varTree;
                                            ch.onUpdateTree();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                    this.removeVariable = null;
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                    this.removeVariable = null;
                                },
                            },
                        ]
                    };
                    this.removeVariable = r[variableId];

                    this.showConfirmModal = true;
                }
            );

        },
        getDependentTitleByType(type) {
            switch (type) {
                case 'PARTNER_VARIABLE':
                    return 'Динамических свойствах';
                case 'ANSWER_SELECTION':
                    return 'Предусловиях';
                case 'BENEFIT_DISPLAY':
                    return 'Рейтинговании выгод';
                case 'SCENARIO_SELECTION':
                    return 'Рейтинговании сценариев';
                default:
                    return `Ошибка: Неизвестный тип зависимости: ${type}`;
            }
        },
        restoreVariable(variableId) {
            getVariables(
                {
                    id: variableId,
                    limit: 1,
                    removed: 1,
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите восстановить свойство "${r[variableId].name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    restoreVariable(
                                        variableId,
                                        () => {
                                            const ch = this.$refs.varTree;
                                            ch.onUpdateTree();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );

        },
    },
};
</script>

<style src="./styles/variable-editor.scss" lang="scss"/>
