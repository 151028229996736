<div class="variable-actions">
    <form-button
        v-if="add"
        type="a"
        class="btn btn-sm btn-green m-auto text-center"
        @click="call('add', id)"
    >
        <template v-slot:center>
            <span class="icon plus green m-auto"></span>
        </template>
    </form-button>
    
    <form-button
        v-if="edit"
        type="a"
        class="btn btn-sm btn-blue text-center m-1"
        @click="call('edit', id)"
    >
        <template v-slot:center>
            <span class="icon edit green m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="copy"
        type="a"
        class="btn btn-sm btn-blue text-center mr-1"
        @click="call('copy', id)"
    >
        <template v-slot:center>
            <span class="icon copy green m-auto"></span>
        </template>
    </form-button>
    
    <form-button
        v-if="remove"
        type="a"
        class="btn btn-sm btn-red m-auto text-center"
        @click="call('delete', id)"
    >
        <template v-slot:center>
            <span class="icon delete red m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="restore"
        type="a"
        class="btn btn-sm btn-red m-auto text-center"
        @click="call('restore', id)"
    >
        <template v-slot:center>
            <span class="icon delete red m-auto"></span>
        </template>
    </form-button>
</div>