<template src="../templates/variable-actions.html"></template>
<script>

import FormButton from '@f/Button';

export default {
    name: 'VariableActions',
    components: {
        FormButton,
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        add: {
            type: Boolean,
            default: true,
        },
        edit: {
            type: Boolean,
            default: true,
        },
        remove: {
            type: Boolean,
            default: true,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        copy: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['action'],
    methods: {
        call(action, id) {
            this.$emit('action', {action, id});
        }
    }
};
</script>
<style src="../styles/variable-actions.scss" lang="scss" />
