<div>
    <page-header>
        <div class="row c">
            <breadcrumbs :items="[{title:'Сценарии'}, {title:'Банк свойств клиента'}]"/>
        </div>
    </page-header>

    <page-title title="Свойства клиентов" :params="{/*subtitle:'Список переменных'*/}"/>

    <div class="container">
        <div class="row filter-block c mt-4 mb-3 flex-direction-column">
            <div class="row-col w-100">
                <div class="col-9">
                    <VariableTreeFilter
                        v-model:filter="filter"
                        v-model:show-removed="showRemoved"
                    />
                </div>
                <div class="col-3">
                    <FormButton
                            label="Создать свойство"
                            :attributes="{class: ['btn', 'btn-green', 'w100']}"
                            @click="newVariable(0)"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="row c mt-4 mb-4">
        <div class="row-col">
            <div class="col variable-tree-content">
                <variable-tree
                    ref="varTree"
                    :filter-text="filter"
                    :show-removed="showRemoved"
                >
                    <template v-slot:line="row">
                        <variable-actions
                            :id="parseInt(row.data.id, 10)"
                            :add="parseInt(row.data.removed, 10) === 0"
                            :copy="parseInt(row.data.removed, 10) === 0"
                            :remove="parseInt(row.data.removed, 10) === 0"
                            :restore="parseInt(row.data.removed, 10) === 1"
                            @action="actionHandler"
                        />
                    </template>
                </variable-tree>
            </div>
        </div>
    </div>

    <teleport to="body" v-if="showConfirmModal">
        <confirm
            v-model:show-modal="showConfirmModal"
            :title="confirmBts.title"
            :buttons="confirmBts.buttons"
        >
            <div v-if="removeVariable && removeVariable.dependents" class="p-4">
                <h3 class="text-red">Используется при формировании условий в:</h3>
                <div v-for="(dependents, type) in removeVariable.dependents" class="mb-2">
                    <h4>{{getDependentTitleByType(type)}}</h4>
                    <div v-for="dependent in dependents">
                        {{dependent.title}}
                        <div v-if="dependent.child" v-for="children in dependent.child" class="pl-2">
                            {{children.title}}
                        </div>
                    </div>
                </div>
            </div>
        </confirm>
    </teleport>

    <teleport to="body" v-if="showEditVariableModal">
        <modal
            @onOk="closeEditVariableModal"
            @onClose="closeEditVariableModal"
        >
            <edit-variable
                ref="editVariable"
                :data="editVariableData"
                :on-save="saveVariable"
                :on-cancel="closeEditVariableModal"
            />
        </modal>
    </teleport>
</div>
